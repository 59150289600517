const _file = "LayoutOptions";
const _double_include = $(window).data("included-" + _file);
$(window).data("included-" + _file, true);

import { Loader } from "@googlemaps/js-api-loader";
import MarkerClusterer from "@googlemaps/markerclustererplus";

// Extend bounds so tooltips are visible without zooming
function calculateExtendedBounds(zoomFactor, minLat, maxLat, minLng, maxLng) {
  const central = (min, max) => (min + max) / 2;
  const halfSize = (min, max) => (max - min) / 2;
  return new google.maps.LatLngBounds(
    {
      lat: central(minLat, maxLat) - halfSize(minLat, maxLat) * zoomFactor,
      lng: central(minLng, maxLng) - halfSize(minLng, maxLng) * zoomFactor,
    },
    {
      lat: central(minLat, maxLat) + halfSize(minLat, maxLat) * zoomFactor,
      lng: central(minLng, maxLng) + halfSize(minLng, maxLng) * zoomFactor,
    }
  );
}

// Checks to see if locationToCheck is a duplicate of a location already in locationsArray. Returns the index of the duplicate, or -1 if no match is found.
function findDuplicates(locationToCheck, locationArray) {
  for (let ind = 0; ind < locationArray.length; ind++) {
    if (Math.abs(locationArray[ind].lat - locationToCheck.lat) < 0.0001 && Math.abs(locationArray[ind].lng - locationToCheck.lng) < 0.0001) {
      return ind;
    }
  }
  return -1;
}

function createListsForSameLocation(locations) {
  const uniqueLocations = []
  for (const location of locations) {
    const index = findDuplicates(location, uniqueLocations)
    if (index >= 0) {
      uniqueLocations[index].name += `, ${location.name}`
    } else {
      uniqueLocations.push({lat: Number(location.lat), lng: Number(location.lng), name: location.name})
    }
  }
  return uniqueLocations
}

function handleNameOverflow(name) {
  if (name.length > 50) {
    return name.substring(0, 50) + "..."
  } else {
    return name;
  }
}

$(() => {
  const mapElement = $("div.map");
  if (!mapElement) return;
  const loader = new Loader({
    apiKey: mapElement.data("key") || "AIzaSyA4tXt-hiDl4BVH0Bfy121ZNGkm3mFXAPw",
    version: "weekly",
  });
  loader.load().then(() => {
    if (mapElement.length) {

      const data = mapElement.data("locations")
      const map = new google.maps.Map(mapElement[0], {
        center: { lat: Number(data[0].lat) || 49.25, lng: Number(data[0].lng) || -122.95 }, // Defaulting to primary location at centre.
        // This will be overriden as soon as there is at least 2 locations to show on the map.
        zoom: 11,
      });

      const locationData = createListsForSameLocation(data); 
      console.log('location data is: ', locationData)

      const bounds = new google.maps.LatLngBounds();
      if (locationData) {
        let markers = [];
        for (const location of locationData) {
          const lat = Number(location.lat);
          const lng = Number(location.lng);
          if (!isNaN(lat) && !isNaN(lng)) {
            const marker = new google.maps.Marker({
              position: { lat, lng },
              title: handleNameOverflow(location.name),
              map
            });
            const details = new google.maps.InfoWindow({
              content: handleNameOverflow(location.name),
            });
            const openWindow = () => {
              details.open(map, marker);
            };
            if (markers.length === 0) openWindow();
            marker.addListener("click", openWindow);
            bounds.extend({ lat, lng });
            markers.push(marker);
          }
        }

        if (locationData.length > 1) {
          const newbounds = calculateExtendedBounds(
            2,
            bounds["Ua"]["g"],
            bounds["Ua"]["i"],
            bounds["La"]["g"],
            bounds["La"]["i"]
          );
          map.fitBounds(newbounds);
        }

        if (markers) {
          const cluster = new MarkerClusterer(map, markers, {
            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            maxZoom: 15
          });
        }
      }
    }
  });
});
